<script lang="ts" setup>
import { ref, watch } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import { useDebounceFn } from "@vueuse/core";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import ArrowIcon from "~/assets/svg/pages/landing/arrow.svg";
import SwipeIcon from "~/assets/svg/pages/landing/ios-swipe.svg";
import PlusIcon from "~/assets/svg/pages/landing/ios-plus.svg";
import DottedIcon from "~/assets/svg/pages/landing/android-dotted.svg";
import PhoneIcon from "~/assets/svg/pages/landing/android-home.svg";
import AndroidDottedIcon from "~/assets/svg/pages/landing/circle-android-dotted.svg";
import useAnalytics from "~/composables/useAnalytics";

type IPlatform = "iOS" | "Android";
interface IProps {
  platform?: IPlatform;
}

const props = withDefaults(defineProps<IProps>(), {
  platform: "iOS",
});
const emit = defineEmits<{
  "update:platform": [value: "iOS" | "Android"];
}>();

const platformRef = ref<IProps["platform"]>(props.platform);
const sliderRef = ref<HTMLDivElement | null>(null);
const isShowBackBtn = ref<boolean>(false);
const isShowForwardBtn = ref<boolean>(true);

const { send } = useAnalytics();

const onScroll = useDebounceFn((event: Event) => {
  const element = event.target as HTMLInputElement;

  isShowBackBtn.value = element.scrollLeft > 0;
  isShowForwardBtn.value = element.scrollLeft === 0;
}, 100);

const onSliderToggle = (value: IPlatform) => {
  platformRef.value = value;
  emit("update:platform", value);

  send("LandingPage:Tutorial:Tab:Click", {
    title: value,
  });
};

const onBackClick = () => {
  if (sliderRef.value) {
    sliderRef.value.scrollLeft = 0;
  }
};

const onForwardClick = () => {
  if (sliderRef.value) {
    sliderRef.value.scrollLeft = sliderRef.value.scrollWidth - sliderRef.value.offsetWidth;
  }
};

watch(sliderRef, () => {
  isShowBackBtn.value = false;
  isShowForwardBtn.value = true;
});

watch(
  () => props.platform,
  () => {
    platformRef.value = props.platform;
  },
);

const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("LandingPage:Tutorial:Slider:View", {
      tab_title: platformRef.value,
    });
  }
};
</script>

<template>
  <VHidden :hide="{ xs: true, ml: false }">
    <section id="tutorial" class="tutorial">
      <div class="tutorial__wrap">
        <div class="tutorial__wrap__indent">
          <h3 class="tutorial__wrap__title">
            Как добавить Магнит Онлайн на рабочий стол
          </h3>
          <h5 class="tutorial__wrap__title-sub">
            Иконка будет на вашем рабочем столе, как любое приложение. <br>Вы сможете быстро
            заходить в Магнит Онлайн, чтобы покупать с выгодой
          </h5>
        </div>
        <div class="tutorial__wrap__buttons">
          <button
            :class="[
              'tutorial__wrap__buttons__btn',
              {
                'tutorial__wrap__buttons__btn-active': platformRef === 'Android',
              },
            ]"
            @click="onSliderToggle('Android')"
          >
            Android
          </button>
          <button
            :class="[
              'tutorial__wrap__buttons__btn',
              { 'tutorial__wrap__buttons__btn-active': platformRef === 'iOS' },
            ]"
            @click="onSliderToggle('iOS')"
          >
            IOS
          </button>
        </div>
      </div>
      <div class="tutorial__slider">
        <div v-intersection-observer="onVisibility">
          <div
            v-if="platformRef === 'iOS'"
            ref="sliderRef"
            class="tutorial__slider__list"
            @scroll="onScroll"
          >
            <div class="tutorial__slider__list__item">
              <h5 class="tutorial__slider__list__item__title">
                Войдите в приложение через браузер Safari
              </h5>
              <div>
                <img
                  class="tutorial__slider__list__item__image"
                  src="~/assets/svg/pages/landing/ios-first.png"
                >
              </div>
            </div>
            <div class="tutorial__slider__list__item">
              <h5 class="tutorial__slider__list__item__title">
                Нажмите на
                <SwipeIcon class="tutorial__slider__list__item__title-swipe" />
                внизу экрана
              </h5>
              <div>
                <img
                  class="tutorial__slider__list__item__image"
                  src="~/assets/svg/pages/landing/ios-second.png"
                >
              </div>
            </div>
            <div class="tutorial__slider__list__item">
              <h5 class="tutorial__slider__list__item__title">
                Выберите
                <PlusIcon class="tutorial__slider__list__item__title-plus" />
                на экран «Домой»
              </h5>
              <div>
                <img
                  class="tutorial__slider__list__item__image"
                  src="~/assets/svg/pages/landing/ios-third.png"
                >
                <img
                  class="tutorial__slider__list__item__image-search"
                  src="~/assets/svg/pages/landing/ios-third-search.png"
                >
              </div>
            </div>
            <div class="tutorial__slider__list__item">
              <h5 class="tutorial__slider__list__item__title">
                Готово! Магнит Онлайн уже ждет вас
              </h5>
              <div>
                <img
                  class="tutorial__slider__list__item__image"
                  src="~/assets/svg/pages/landing/ios-fourth.png"
                >
              </div>
            </div>
          </div>
          <div
            v-if="platformRef === 'Android'"
            ref="sliderRef"
            class="tutorial__slider__list"
            @scroll="onScroll"
          >
            <div class="tutorial__slider__list__item">
              <h5 class="tutorial__slider__list__item__title">
                Войдите в приложение через браузер Chrome
              </h5>
              <div>
                <img
                  class="tutorial__slider__list__item__image"
                  src="~/assets/svg/pages/landing/android-first.png"
                >
              </div>
            </div>
            <div class="tutorial__slider__list__item">
              <h5 class="tutorial__slider__list__item__title">
                Нажмите на иконку меню
                <DottedIcon class="tutorial__slider__list__item__title-dotted" />
              </h5>
              <div>
                <img
                  class="tutorial__slider__list__item__image"
                  src="~/assets/svg/pages/landing/android-second.png"
                >
                <AndroidDottedIcon class="tutorial__slider__list__item__image-dotted" />
              </div>
            </div>
            <div class="tutorial__slider__list__item tutorial__slider__list__item_android">
              <h5 class="tutorial__slider__list__item__title tutorial__slider__list__item__title">
                Выберите
                <PhoneIcon class="tutorial__slider__list__item__title-phone" />
                Добавить на&nbsp;главный экран
              </h5>
              <div>
                <img
                  class="tutorial__slider__list__item__image"
                  src="~/assets/svg/pages/landing/android-third.png"
                >
                <img
                  class="tutorial__slider__list__item__image-main"
                  src="~/assets/svg/pages/landing/line-android-main.png"
                >
              </div>
            </div>
            <div class="tutorial__slider__list__item">
              <h5 class="tutorial__slider__list__item__title">
                Готово! Магнит Онлайн уже ждет вас
              </h5>
              <div>
                <img
                  class="tutorial__slider__list__item__image"
                  src="~/assets/svg/pages/landing/android-fourth.png"
                >
              </div>
            </div>
          </div>
        </div>
        <div v-show="isShowBackBtn" class="tutorial__slider__buttons-back" @click="onBackClick">
          <ArrowIcon class="tutorial__slider__buttons-back__arrow" />
        </div>
        <div
          v-show="isShowForwardBtn"
          class="tutorial__slider__buttons-forward"
          @click="onForwardClick"
        >
          <ArrowIcon />
        </div>
      </div>
    </section>
  </VHidden>
</template>

<style lang="postcss">
.tutorial {
  font-family: var(--pl-font-family);
  padding: 20px 0;

  .os-android & {
    display: none;
  }

  &__wrap {
    margin: 0 auto;
    padding: 0 20px;

    &__indent {
      padding-right: 20px;
    }

    &__title {
      font: var(--pl-font-headline-large);
      margin-bottom: 16px;
      max-width: 620px;
    }

    &__title-sub {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      max-width: 896px;
      margin-bottom: 32px;

      br {
        display: none;
      }
    }

    &__buttons {
      display: none;
      margin: 32px 0 24px;

      &__btn {
        background-color: var(--color-black-10);
        border-radius: 20px;
        border: 0;
        color: #242424;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        height: 32px;
        line-height: 24px;
        margin-right: 16px;
        padding: 4px 13px;
        transition: background-color 0.16s ease-in-out;

        &-active {
          background-color: var(--color-black);
          color: #fff;
        }
      }
    }
  }

  &__slider {
    position: relative;

    &__list {
      display: flex;
      -ms-overflow-style: none;
      overflow-x: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
      padding: 0 20px;

      &::-webkit-scrollbar {
        display: none;
      }

      &__item {
        background-color: #edf4ff;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        height: 400px;
        justify-content: space-between;
        margin-right: 16px;
        min-width: 300px;
        overflow: hidden;
        padding: 20px 20px 0;
        position: relative;

        &::before {
          align-items: center;
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          font-size: 40px;
          font-weight: 600;
          height: 77px;
          justify-content: center;
          left: 8px;
          line-height: 40px;
          position: absolute;
          top: 80px;
          width: 77px;
          z-index: 1;
        }

        &:nth-child(1)::before {
          content: "1";
        }

        &:nth-child(2)::before {
          content: "2";
        }

        &:nth-child(3)::before {
          content: "3";
        }

        &:nth-child(4)::before {
          content: "4";
        }

        &:last-child {
          margin-right: 0;
        }

        &__title {
          font-size: 18px;
          font-weight: 600;
          line-height: 26px;
          margin-bottom: 44px;
          min-height: 52px;

          &-swipe {
            margin: 0 2px -4px;
            width: 18px;
          }

          &-plus {
            margin: 0 2px -4px;
            width: 18px;
          }

          &-dotted {
            height: 18px;
            margin: 0 0 -2px 6px;
          }

          &-phone {
            height: 22px;
            margin: 0 4px -2px;
          }
        }

        &__image {
          bottom: 0;
          height: 280px;
          max-width: 100%;
          position: absolute;
          width: 245px;

          &-search {
            bottom: 14px;
            left: 0;
            max-width: 100%;
            position: absolute;
            z-index: 1;
          }

          &-dotted {
            max-width: 100%;
            position: absolute;
            right: 0;
            top: 124px;
            z-index: 1;
          }

          &-main {
            bottom: 18px;
            right: -10px;
            height: 87px;
            max-width: 100%;
            position: absolute;
            z-index: 1;
          }
        }
      }
    }

    &__buttons {
      &-back,
      &-forward {
        display: none;
      }
    }
  }
}

@media (--pl-viewport-from-m) {
  .tutorial {
    padding: 32px 0;

    &__wrap {
      padding: 0 20px;
    }
  }
}

@media (--pl-viewport-from-ml) {
  .tutorial {
    .os-android & {
      display: block;
    }

    &__wrap {
      padding: 0 32px;

      &__buttons {
        display: block;
      }
    }

    &__slider {
      &__list {
        padding: 0 32px;
      }
    }
  }
}

@media (--pl-viewport-from-l) {
  .tutorial {
    &__wrap {
      &__title {
        font: var(--pl-font-display-medium);
      }
    }

    &__slider {
      &__list {
        padding-right: 0;
      }

      &__buttons {
        &-back,
        &-forward {
          align-items: center;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0 4px 8px rgb(0 0 0 / 12%);
          cursor: pointer;
          display: flex;
          height: 56px;
          justify-content: center;
          position: absolute;
          top: calc(50% - 28px);
          width: 56px;
        }

        &-back {
          left: 24px;

          &__arrow {
            transform: rotate(-180deg);
          }
        }

        &-forward {
          right: 24px;
        }
      }
    }
  }
}

@media (--pl-viewport-from-xl) {
  .tutorial {
    &__wrap {
      max-width: 1200px;
      padding: 0;

      &__title {
        max-width: 100%;
      }

      &__title-sub {
        max-width: 100%;

        br {
          display: initial;
        }
      }
    }

    &__slider {
      max-width: 1200px;
      margin: auto;

      &__list {
        padding: 0;
      }

      &__buttons {
        &-back {
          left: -24px;
        }

        &-forward {
          right: -24px;
        }
      }
    }
  }
}
</style>
