<script setup lang="ts">
import { useRouter } from "vue-router";
import cloneDeep from "lodash.clonedeep";
import type { ISubMenuLinks } from "@magnit/unit-navigation/src/VNavigation.types";
import VFooter from "@magnit/unit-footer/src/VFooter.vue";
import {
  CLIENT_LINKS,
  COMPANY_LINKS,
  MANAGEMENT_LINKS,
} from "@magnit/unit-navigation/src/VNavigation.vars";
import { Routes } from "~/utils/routes";

const router = useRouter();
const clientLinks = cloneDeep(CLIENT_LINKS);
const companyLinks = cloneDeep(COMPANY_LINKS);
clientLinks.links[0].url =
  "https://magnit.ru/offer/promopokarte/?utm_source=my.magnit.ru&utm_campaign=karta&utm_medium=footer";
companyLinks.links.splice(2, 0, {
  label: "Условия использования файлов cookies",
  url: Routes.CookiePolicy,
  onClick: (e: Event) => {
    e.preventDefault();
    router.push(Routes.CookiePolicy);
  },
});
const links: ISubMenuLinks = [clientLinks, MANAGEMENT_LINKS, companyLinks];
</script>

<template>
  <VFooter project="my-magnit" :links />
</template>
