<script lang="ts" setup>
import { watch } from "vue";
import { useElementVisibility } from "@vueuse/core";
import VText from "@magnit/core/src/components/VText/VText.vue";
import useAnalytics from "~/composables/useAnalytics";

const rootRef = ref<HTMLElement | null>(null);
const rootRefIsVisible = useElementVisibility(rootRef);

const { send } = useAnalytics();

watch(rootRefIsVisible, (newRootRefIsVisible) => {
  if (newRootRefIsVisible) {
    send("LandingPage:Online:Benefits:View");
  }
});
</script>

<template>
  <section id="cards" ref="rootRef" class="cards">
    <div class="cards-wrap">
      <VText
        tag="h3"
        :font="{
          xs: 'headline-small',
          ml: 'headline-large',
          l: 'display-medium',
        }"
        class="cards-wrap__title"
      >
        Что ещё вы получите с Магнит Онлайн:
      </VText>
      <div class="cards-wrap__list">
        <div class="cards-wrap__list-item">
          <img
            class="cards-wrap__list-item__icon"
            src="~/assets/svg/pages/landing/notify-icon.svg?url"
            alt="Бонусы"
          >
          <h5 class="cards-wrap__list-item__title">
            Уведомления о выгодных предложениях
          </h5>
          <p class="cards-wrap__list-item__text">
            Узнавайте о скидках перед походом в магазин
          </p>
        </div>
        <div class="cards-wrap__list-item">
          <img
            class="cards-wrap__list-item__icon"
            src="~/assets/svg/pages/landing/el-check-icon.svg?url"
            alt="Корзина бонусов"
          >
          <h5 class="cards-wrap__list-item__title">
            Электронные чеки вместо бумажных
          </h5>
          <p class="cards-wrap__list-item__text">
            Получайте чеки на e-mail
          </p>
        </div>
        <div class="cards-wrap__list-item">
          <img
            class="cards-wrap__list-item__icon"
            src="~/assets/svg/pages/landing/loyality-icon.svg?url"
            alt="Виртуальная карта"
          >
          <h5 class="cards-wrap__list-item__title">
            Вопросы и ответы по программе лояльности
          </h5>
          <p class="cards-wrap__list-item__text">
            Узнайте, за что начисляются бонусы и как получать больше
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss">
.cards {
  padding: 32px 20px;
}

.cards-wrap {
  margin: 0 auto;
  max-width: 1200px;

  &__title {
    margin-bottom: 16px;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }

  &__list-item {
    border-radius: 30px;
    padding: 16px 24px 32px;
    text-align: center;

    &__icon {
      height: 120px;
    }

    &__title {
      font: var(--pl-font-title-large);
      margin-bottom: 8px;
      text-align: left;
    }

    &__text {
      font: var(--pl-font-body-large-accent-low);
      text-align: left;
    }
  }
}

@media (--pl-viewport-from-m) {
  .cards-wrap {
    &__title {
      max-width: 520px;
    }
  }
}

@media (--pl-viewport-from-ml) {
  .cards {
    padding: 64px 32px;
  }

  .cards-wrap {
    &__title {
      max-width: 704px;
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (--pl-viewport-from-l) {
  .cards-wrap {
    &__title {
      max-width: 896px;
    }

    &__list {
      grid-template-columns: repeat(3, 1fr);
    }

    &__list-item {
      &__title {
        font: var(--pl-font-headline-small);
      }
    }
  }
}
</style>
