<script lang="ts" setup>
import { ref, watch } from "vue";
import { useElementVisibility } from "@vueuse/core";
import { useRouter } from "vue-router";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import { Routes } from "~/utils/routes";
import useAnalytics from "~/composables/useAnalytics";
import { usePwaStore } from "~/store/pwa";
import { usePlatformStore } from "~/store/platform";

type IVLandingHeaderSections = "benefits" | "online" | "tutorial";

const emit = defineEmits<{
  sectionClick: [value: "iOS" | "Android"];
}>();

const router = useRouter();
const { send } = useAnalytics();
const pwaStore = usePwaStore();
const platformStore = usePlatformStore();

const headerRef = ref<null | HTMLElement>(null);
const headerRefIsVisible = useElementVisibility(headerRef);
const isPwa = computed(() => platformStore.platformInfo.pwa);

const onScrollIntoView = (section: IVLandingHeaderSections) => {
  const options: ScrollIntoViewOptions = {
    block: "start",
    behavior: "smooth",
  };

  if (section === "benefits") {
    const benefitsEl = document.getElementById("benefits");
    benefitsEl?.scrollIntoView(options);
  }

  if (section === "online") {
    const cardsEl = document.getElementById("online");
    cardsEl?.scrollIntoView(options);
  }

  if (section === "tutorial") {
    const tutorialEl = document.getElementById("tutorial");
    tutorialEl?.scrollIntoView(options);
  }
};

const onScrollIntoViewTutorial = (value: "iOS" | "Android", title: string) => {
  const tutorialEl = document.getElementById("tutorial");

  tutorialEl?.scrollIntoView({
    block: "start",
    behavior: "smooth",
  });

  emit("sectionClick", value);

  send("LandingPage:Apps:Click", {
    title,
  });
};

const onHeaderClick = (section: IVLandingHeaderSections, title: string) => {
  onScrollIntoView(section);
  send("LandingPage:Main:Header:Click", {
    title,
    target_url: `#${section}`,
  });
};

const onLoginHeaderClick = () => {
  send("LandingPage:Main:Header:Click", {
    title: "Войти",
    target_url: Routes.Login,
  });
  router.push(Routes.Login);
};

const onLoginButtonClick = () => {
  send("LandingPage:Main:Button:Click", {
    title: "Войти",
    target_url: Routes.Login,
  });
  router.push(Routes.Login);
};

const onOpenPwaClick = () => {
  if (!isPwa.value && typeof window !== "undefined") {
    window.open(pwaStore.DEFAULT_PWA_LINK, "_blank");
  }
};

const onInstallPwaClick = () => {
  pwaStore.showPrompt();
};

watch(headerRefIsVisible, (newHeaderRefIsVisible) => {
  if (newHeaderRefIsVisible) {
    send("LandingPage:Main:View");
  }
});

const profits = [
  "Открывайте карту в 2 клика",
  " Следите за балансом бонусов",
  "Получайте больше бонусов и скидок, чем с пластиковой картой",
];
</script>

<template>
  <header ref="headerRef" class="header">
    <div class="header-wrap">
      <div class="header__nav">
        <img
          class="header__nav__img"
          src="~/assets/svg/pages/landing/magnit-logo.svg?url"
          alt="Магнит"
        >
        <nav class="header__nav__container">
          <span class="header__nav-item" @click="onLoginHeaderClick"> Войти </span>
          <span class="header__nav-item" @click="onHeaderClick('online', 'Карта Магнит')">
            Карта Магнит Плюс
          </span>
          <VHidden :hide="{ xs: true, ml: false }">
            <span class="header__nav-item" @click="onHeaderClick('tutorial', 'Как установить')">
              Как установить
            </span>
          </VHidden>
        </nav>
      </div>

      <div class="header__content">
        <div class="header__content__text">
          <VText
            tag="h1"
            :font="{
              xs: 'headline-large',
              m: 'display-medium',
              l: 'display-large',
            }"
          >
            Карта Магнит Плюс в&nbsp;телефоне
          </VText>
          <VText tag="h3" :font="{ xs: 'title-large', l: 'headline-large' }">
            Добавьте иконку Магнит Онлайн&nbsp;на&nbsp;рабочий&nbsp;стол
          </VText>
          <ul>
            <VText
              v-for="(item, i) in profits"
              :key="i"
              tag="li"
              :font="{ xs: 'body-large-accent', l: 'headline-small' }"
            >
              {{ item }}
            </VText>
          </ul>
        </div>
        <div class="header__content__button">
          <VButton class="header__content__button__login" @click="onLoginButtonClick">
            Войти
          </VButton>
          <VButton
            theme="invert"
            @click="onScrollIntoViewTutorial('Android', 'Добавить на Android')"
          >
            <span class="header__content__button__item">
              <img src="~/assets/svg/pages/landing/android-icon.svg?url" alt="Android">
              Добавить на Android
            </span>
          </VButton>
          <VButton theme="invert" @click="onScrollIntoViewTutorial('iOS', 'Добавить на IOS')">
            <span class="header__content__button__item">
              <img src="~/assets/svg/pages/landing/apple-icon.svg?url" alt="Apple">
              Добавить на IOS
            </span>
          </VButton>
        </div>
      </div>
      <div class="header__content__app-box">
        <div class="header-bg">
          <img src="~/assets/svg/pages/landing/header-bg.svg?url">
        </div>
      </div>
    </div>
    <div class="header__button">
      <VButton v-if="!isPwa && pwaStore.state.appInstalled" full-width @click="onOpenPwaClick">
        Открыть
      </VButton>
      <VButton
        v-if="!isPwa && !pwaStore.state.appInstalled && pwaStore.state.installPromptBannerOpen"
        full-width
        :loading="pwaStore.state.installInProgress"
        @click="onInstallPwaClick"
      >
        Добавить на рабочий стол
      </VButton>
      <VButton
        v-if="isPwa || (!pwaStore.state.appInstalled && !pwaStore.state.installPromptBannerOpen)"
        full-width
        @click="onLoginButtonClick"
      >
        Войти
      </VButton>
    </div>
  </header>
</template>

<style lang="postcss">
.header {
  background-color: #dceaff;
  font-family: var(--pl-font-family);
  overflow: hidden;
  position: relative;

  &-wrap {
    position: relative;
    z-index: var(--pl-z-index-level2);
  }

  &__button {
    bottom: 16px;
    display: block;
    position: fixed;
    width: 100%;
    padding: 0 20px;
    z-index: var(--pl-z-index-level3);
  }

  &-bg {
    z-index: -1;
    position: absolute;
    left: calc(50% - 130px);
    top: -6px;
  }

  &__content {
    padding: 0 20px;

    &__text {
      margin-top: 16px;
      position: relative;
      z-index: 1;
      color: var(--pl-text-primary);
      font-family: var(--pl-font-family);
      max-width: 520px;

      h1 {
        margin-bottom: 8px;
      }

      h3 {
        max-width: 450px;
      }

      ul {
        margin-top: 24px;
        max-width: 480px;

        li {
          margin-bottom: 12px;
          padding-left: 20px;
          position: relative;

          &:last-child {
            margin-bottom: 0;
          }

          &::before {
            background-color: var(--pl-text-primary);
            border-radius: 50%;
            content: "";
            height: 8px;
            left: 0;
            position: absolute;
            top: 8px;
            width: 8px;
          }
        }
      }
    }

    &__button {
      display: none;
    }

    &__app-box {
      max-width: 1200px;
      background-image: url("~/assets/svg/pages/landing/magnit-app-desktop.png");
      height: 282px;
      background-position: center;
      background-size: 512px;
      background-repeat: no-repeat;
      margin: 0 auto 48px;
      position: relative;
    }
  }

  &__nav {
    border-bottom: 1px solid var(--pl-border-primary-default);
    padding: 24px 0 16px;
    margin: 0 20px;
    text-align: center;

    &__container {
      display: flex;
      justify-content: flex-start;
      margin-top: 16px;
    }

    &-item {
      color: var(--pl-text-primary);
      font: var(--pl-font-body-large-accent);
      cursor: pointer;
      margin-right: 16px;
      text-decoration: none;
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(3) {
        .os-android & {
          display: none;
        }
      }
    }
  }
}

@media (--pl-viewport-from-m) {
  .header {
    &__nav {
      text-align: left;
    }

    &__content {
      &__app-box {
        margin-top: -16px;
        padding: 0 24px;
      }
    }
  }
}

@media (--pl-viewport-from-ml) {
  .header {
    &__nav {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 32px 0 16px;
      margin: 0 32px;

      &__container {
        margin-top: 0;
      }

      &-item {
        &:nth-child(3) {
          .os-android & {
            display: inline-flex;
          }
        }
      }
    }

    &-bg {
      left: calc(50% - 170px);
      top: -12px;
    }

    &__content {
      position: relative;
      padding: 0 32px;

      &__text {
        max-width: 100%;

        h3,
        ul {
          max-width: 100%;
        }
      }

      &__app-box {
        margin-top: 44px;
        background-position: calc(50% - 42px) 0;
        background-size: 532px;
      }

      &__button {
        display: flex;
        gap: 20px;
        margin-top: 24px;
        position: relative;
        z-index: 2;

        &__login {
          width: 106px;
        }

        &__item {
          display: flex;

          img {
            margin-right: 8px;
          }
        }
      }
    }

    &__button {
      display: none;
    }
  }
}

@media (--pl-viewport-from-l) {
  .header {
    padding: 0 32px;

    &__nav {
      margin: 0 auto;
      max-width: 1200px;
      padding: 40px 0 36px;

      &-item {
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }

        &:nth-child(3) {
          display: block;
        }
      }
    }

    &__content {
      margin: 0 auto;
      max-width: 1200px;
      padding: 0;

      &__text {
        margin-top: 82px;
        max-width: 520px;

        ul {
          margin-top: 40px;

          li {
            margin-bottom: 18px;
            padding-left: 50px;

            &::before {
              height: 12px;
              top: 10px;
              width: 12px;
            }
          }
        }
      }

      &__button {
        &__login {
          width: 160px;
        }
      }

      &__app-box {
        padding: 0;
        position: absolute;
        left: 440px;
        top: 300px;
        margin: 0;
        width: 512px;
        background-size: 100%;
        background-position: center;
      }
    }

    &-bg {
      left: calc(50% - 130px);
      top: -6px;
    }
  }
}

@media (--pl-viewport-from-xl) {
  .header {
    &__content {
      &__text {
        margin-top: 88px;
      }

      &__button {
        margin-top: 64px;
      }

      &__app-box {
        top: 270px;
        width: 612px;
        height: 330px;
        left: calc(50% + 50px);
      }
    }

    &-bg {
      width: 1000px;
      left: -130px;
      top: -212px;

      img {
        width: 100%;
      }
    }
  }
}
</style>
